<template>
	
	
	<div class="goods-list" v-loading="loading">
		<div class="in-sousuo">
			<div class="sousuo-box">
				 
				<input type="text"   v-model="keyword" @keyup.enter="search" maxlength="50" />
				<el-button class="serbtn" type="primary" size="small" @click="search">搜索</el-button>
			</div>
			<div class="hot-search-words" v-if="hotSearchWords.length">
				 
				<ul>
					<li v-for="(item, index) in hotSearchWords" :key="index" @click="$router.push({ path: '/spotgoods', query: { keyword: item, search_type: 'goods',category_id:filters.category_id,level:1 } })">
						{{ item }}
					</li>
				</ul>
			</div>
		</div> 
		<!-- 搜索关键字 -->
		<div class="search_bread" v-if="keyword">
			<router-link to="/">首页</router-link>
			<span class="padd-10">/交易大厅/远期交付/</span>
			<span class="keyword">{{ keyword}}</span>
		</div>
		<div class="search_bread" v-else-if="!keyword && catewords">
			<router-link to="/">首页</router-link>
			<span class="padd-10">/交易大厅/远期交付/</span>
			<router-link :to="{ path: '/spotgoods', query: { category_id: first_index, level: 1 } }">{{ catewords.split('/')[0] }}</router-link>
			<!-- <span class="keyword">{{ catewords.split('/')[0] }}</span> -->
			<span v-if="(filters.category_level == 2 || filters.category_level == 3) && catewords.split('/')[1]" class="padd-10">/</span>
			<span v-if="(filters.category_level == 2 || filters.category_level == 3) && catewords.split('/')[1]" class="keyword">{{ catewords.split('/')[1] }}</span>
		</div>
		

		<!-- 品牌过滤记录区 -->
		<!-- <div class="attr_filter" v-if="catewords">
			<el-tag type="info" v-if="choosedBrand" closable @close="colseBrand" effect="plain">
				<span v-if="choosedBrand" class="title">品牌：</span>
				{{ choosedBrand.brand_name }}
			</el-tag>
			<span v-for="item in attributeList" :key="item.attr_id">
				<el-tag type="info" v-if="item.selectedValue" closable @close="colseAttr(item)" effect="plain">
					<span class="title" v-if="item.attr_name">{{ item.attr_name }}：</span>
					{{ item.selectedValue }}
				</el-tag>
			</span>
		</div> -->

		<!-- 品牌属性筛选区 -->
		<template>
			<div class="category" v-if="0">
				<!-- 品牌 -->
				<!-- <div class="brand">
					<div class="table_head">品牌：</div>
					<div class="table_body">
						<div class="initial">
							<span type="info" effect="plain" hit @mouseover="handleChangeInitial('')">所有品牌</span>
							<span type="info" effect="plain" hit v-for="item in brandInitialList" :key="item" @mouseover="handleChangeInitial(item)">{{ (item || '').toUpperCase() }}</span>
						</div>
						<div class="content">
							<el-card v-for="item in brandList" :key="item.id" body-style="padding: 0;height: 100%;" shadow="hover" v-show="currentInitial === '' || item.brand_initial === currentInitial"
							 class="brand-item">
								<el-image :src="$img(item.image_url || defaultGoodsImage)" :alt="item.brand_name" :title="item.brand_name" fit="contain"
								 @click="onChooseBrand(item)" />
							</el-card>
						</div>
					</div>
				</div> -->

				<!-- 属性 -->
				<div class="brand" v-for="item in attributeList" :key="'attr' + item.attr_id">
					<div class="table_head">{{ item.attr_name }}：</div>
					<div class="table_body">
						<div class="content">
							<span v-for="subitem in item.child" :key="subitem.attr_value_id">
								<el-checkbox :label="subitem.attr_value_name" v-if="item.isMuiltSelect" :checked="subitem.selected" @change="setAttrSelectedMuilt(item, subitem)"></el-checkbox>
								<el-link :underline="false" v-else @click="setAttrSelected(item, subitem)">{{ subitem.attr_value_name }}</el-link>
							</span>
						</div>
					</div>
					<div class="table_op">
						<el-button size="small" icon="el-icon-circle-plus-outline" @click="setMuiltChoose(item)">多选</el-button>
					</div>
				</div>
			</div>
		</template>

		<div class="list-wrap">
			<div class="goods-recommended" >
				<div class="goods-recommend" v-loading="loading">
					<div><router-link :to="{ path: '/spotgoods', query: { category_id: 0, level: 1 ,gid:filters.relatedgoodsid} }">现货交易</router-link></div>
					<div class="active">远期交付</div>
					<div><router-link :to="{ path: '/ordersale' , query: { category_id: 11, level: 1 } }">竞买竞卖</router-link></div>
				</div>
			</div>
			<div class="list-right">
				 
				<div class="cargo-list" >
					<el-tabs class="goods-tab"  type="card"  >
						 <el-tab-pane label="全部商品">
							 <el-tabs class="goods-tab" v-model="cateactive"  type="card" @tab-click="handleClickStatus">  
							 	 <el-tab-pane label="全部" name="all">
											<div class="pjinfo">
												 <div class="goods-info">
												 
													<div class="item" v-for="(item, index) in cargoList" :key="item.goods_id" >
														
														<div class="goods-name">{{ item.goods_name }}</div> 
														<div v-if="0" class="goods-img"><img  :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" /></div>
														<div class="goods-attr">
															<div v-for="(aitem,aindex) in item.attrres" class="attr-item">
																<div class="at-left">{{aitem.attr_name}}</div>
																<div class="at-right">{{aitem.attr_value_name}}</div>
															</div>
														</div>
														<div class="goods-buy">
															<el-button  @click="$router.pushToTab({ path: '/presku-' + item.sku_id })">查看详情</el-button> 
															<el-button  @click="buycart(item.sku_id,1)">立即购买</el-button> 
														</div> 
														<div class="price-wrap" v-if="0">
															<div class="price">
																<p>￥</p>
																{{ showPrice(item) }}
																<div class="price-icon-wrap">
																	<img v-if="item.member_price && item.member_price == showPrice(item)" :src="$img('upload/uniapp/index/VIP.png')"/>
																	<img v-else-if="promotion_type==1" :src="$img('upload/uniapp/index/discount.png')"/>
																</div>
															</div>
															<div class="market-price">￥{{ item.market_price }}</div>
														</div>
														 
														<!-- <div class="shop_name">{{item.site_name}}</div> -->
														<div class="saling" v-if="0">
															<div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div>
															<!-- <div class="is_own" v-if="item.is_own == 1">自营</div> -->
															<div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div>
														</div>
													</div>
												 
												 
												 
												 
												 </div>
												 <div class="pager">
													<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage"
													 :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
													 hide-on-single-page></el-pagination>
												 </div>
											</div>
										</el-tab-pane>  
										
										<el-tab-pane  :name="'cid-'+item.category_id" :label="item.category_name" v-for="item in first_child_list">
											 <div class="pjinfo">
											 	 <div class="goods-info">
											 	 
											 		<div class="item" v-for="(item, index) in cargoList" :key="item.goods_id" >
											 			
											 			<div class="goods-name">{{ item.goods_name }}</div> 
											 			<div v-if="0" class="goods-img"><img  :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" /></div>
											 			<div class="goods-attr">
											 				<div v-for="(aitem,aindex) in item.attrres" class="attr-item">
											 					<div class="at-left">{{aitem.attr_name}}</div>
											 					<div class="at-right">{{aitem.attr_value_name}}</div>
											 				</div>
											 			</div>
											 			<div class="goods-buy">
											 				<el-button  @click="$router.pushToTab({ path: '/presku-' + item.sku_id })">查看详情</el-button> 
											 				<el-button  @click="buycart(item.sku_id,1)">立即购买</el-button> 
											 			</div> 
											 			<div class="price-wrap" v-if="0">
											 				<div class="price">
											 					<p>￥</p>
											 					{{ showPrice(item) }}
											 					<div class="price-icon-wrap">
											 						<img v-if="item.member_price && item.member_price == showPrice(item)" :src="$img('upload/uniapp/index/VIP.png')"/>
											 						<img v-else-if="promotion_type==1" :src="$img('upload/uniapp/index/discount.png')"/>
											 					</div>
											 				</div>
											 				<div class="market-price">￥{{ item.market_price }}</div>
											 			</div>
											 			 
											 			<!-- <div class="shop_name">{{item.site_name}}</div> -->
											 			<div class="saling" v-if="0">
											 				<div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div>
											 				<!-- <div class="is_own" v-if="item.is_own == 1">自营</div> -->
											 				<div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div>
											 			</div>
											 		</div>
											 	 
											 	 
											 	 
											 	 
											 	 </div>
											 	 <div class="pager">
											 		<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage"
											 		 :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
											 		 hide-on-single-page></el-pagination>
											 	 </div>
											 </div>
										</el-tab-pane>
										 
									</el-tabs>	
						 </el-tab-pane> 
						 <el-tab-pane label="行情分析">
							 <div class="pjinfo">
							   行情分析
							 </div>
						 </el-tab-pane>
						 <el-tab-pane label="自选商品">
						 		<div class="pjinfo">
						 			 <div class="goods-info">
						 			 
						 				<div class="item" v-for="(item, index) in cartlist" :key="item.goods_id" >
						 					
						 					 <div class="goods-name">{{ item.sku_name }}</div>  
						 					 
						 					 <div class="goods-attr">
						 						 <div  class="attr-item">
						 							 <div class="at-left">数量</div>
						 							 <div class="at-right">{{item.num}}</div>
						 						 </div>
						 						<div v-for="(aitem,aindex) in item.attrres" class="attr-item">
						 							<div class="at-left">{{aitem.attr_name}}</div>
						 							<div class="at-right">{{aitem.attr_value_name}}</div>
						 						</div>
						 					</div>
						 					<div class="goods-buy">
						 						<el-button  @click="deleteCart(item.cart_id)">删除自选</el-button> 
						 						<el-button  @click="buycart(item.sku_id,item.num)">立即购买</el-button> 
						 					</div> 
						 				  
						 				</div>
						 			 
						 			 
						 			 
						 			 
						 			 </div>
						 			  
						 		</div>
						 </el-tab-pane>
					</el-tabs>	
					
				</div>
				 
			</div>
		</div>
	</div>
</template>

<script>
	import BreadCrumbs from "@/components/BreadCrumbs";
	import GoodsRecommend from "@/components/GoodsRecommend";
	import list from "./list";

	export default {
		name: "list",
		components: {
			BreadCrumbs,
			GoodsRecommend
		},
		data: () => {
			return {};
		},
		mixins: [list],
		created() {},
		methods: {
			showPrice(data){
				let price = data.discount_price;
				if(data.member_price && parseFloat(data.member_price) < parseFloat(price)) price = data.member_price;
				return price;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.padd-10{
		padding: 0 10px;
	}
	.goods-list {
		background: #fff;
		 
		padding-top: 10px;
		.top_cate_class{
			margin-top:10px;
			position:absolute; 
			left:0;
			border-bottom:solid 3px #61433c;
		    z-index:9;
			width:100%;
			 ul{
				 width:1200px;
				 margin: 0px auto;  
				 li{
				 	display: inline-block;
				 	height:30px;
				 	line-height:30px;
				 	padding: 0 10px; 
				 }
				 .active{
				 	color:#61433c;
				 	background-color: #e5e0df;
				 }
			 }
			
			
		}
	}

	.search_bread {
		display: inline-block;
		font-size: 12px;
		color:#999999;
		border-bottom:solid 1px #dddddd;
		margin: 0px auto;
		margin-top:40px;
		margin-bottom:20px;
		width: 100%;
		padding: 10px;
		p{
			float: left;
		}
		a{
			color:#999999;
		}
		li{
			float: left;
			padding: 0 10px;
		}
		.active a{
			color:#ff547b !important;
		}
	}

	.selected_border {
		border: 1px solid $base-color;
	}

	.attr_filter {
		.el-tag {
			margin-right: 5px;
			margin-bottom: 10px;
			border-radius: 0;

			.title {
				color: $base-color;
				font-size: 15px;
			}
		}
	}

	.category {
		margin: 0 auto 10px auto;
		border: 1px solid #eeeeee;

		.brand {
			border-bottom: 1px solid #eeeeee;
			display: flex;
			flex-direction: row;

			&:last-child {
				border-bottom: none;
			}

			.table_head {
				width: 140px;
				min-width: 140px;
				border-right: 1px solid #eeeeee;
				padding-left: 10px;
				padding-top: 10px;
				background-color: #f2f6fc;
			}

			.table_body {
				flex-grow: 1;

				.initial {
					margin: 5px auto 10px 10px;

					span {
						border: 0;
						margin: 0;
						padding: 5px 10px;
						border: 0;
						border-radius: 0;

						&:hover {
							background-color: $base-color;
							color: #ffffff;
						}
					}
				}

				.content {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					padding: 10px;
					width: 900px;

					.brand-item {
						margin-right: 10px;
						margin-top: 10px;
					}

					.el-card {
						width: 125px;
						height: 45px;

						&:hover {
							border: 1px solid $base-color;
							cursor: pointer;
						}
					}

					span {
						margin: auto 25px;
					}
				}
			}

			.table_op {
				margin-top: 5px;
				margin-right: 5px;
			}

			.el-image {
				width: 100%;
				height: 100%;
			}
		}
	}

	.list-wrap {
		overflow: hidden;
	}

	.goods-recommended {
		width: 15%;
		background-color: #e5e5e5;
		float: left;
		margin-right: 2px;
		text-align: center;
		.goods-recommend{
			div{
				height:50px;
				line-height:50px;
				font-size:13px; 
				cursor:pointer;
			}
			.active{
				background-color: #a6a6a6;
				font-weight: bolder;
			}
		}
	}
	.list-right{
		width:84%;
		float:left; 
	}
	.sort {
		display: flex;
		align-items: center;

		.item {
			display: flex;
			align-items: center;
			padding: 5px 15px;
			border: 1px solid #f1f1f1;
			border-left: none;
			cursor: pointer;

			&:hover {
				background: $base-color;
				color: #fff;
			}
		}

		.item-other {
			display: flex;
			align-items: center;
			padding: 5px 15px;
			border: 1px solid #f1f1f1;
			border-left: none;
			cursor: pointer;
		}

		.input-wrap {
			display: flex;
			align-items: center;

			.price_range {
				margin-left: 360px;
			}

			span {
				padding-left: 10px;
			}

			.el-input {
				width: 150px;
				margin-left: 10px;
			}

			.el-button {
				margin: 0 17px;
			}
		}

		>div:first-child {
			border-left: 1px solid #f1f1f1;
		}
	}

	.goods-info { 
		display: flex;
		flex-wrap: wrap;

		.item {
			width:30%;
			margin: 10px 20px 0 0;
			border: 1px solid #eeeeee;
			 
			position: relative; 
            text-align:center;
			&:nth-child(4n) {
				margin-right: initial !important;
			}

			&:hover {
				border: 1px solid $base-color;
			}

			.img-wrap {
				width: 178px;
				height: 178px;
				cursor: pointer;
				padding: 10px;
				float:left;
				border-radius:25px;
			}

			.goods-name {
				padding: 0 3px;
				height:35px;
				line-height:35px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				word-break: break-all;
				background-color: #eeeeee;
				cursor: pointer;
                color:#333333;
				&:hover {
					color: $base-color;
				}
			}
			.goods-img{
				img{
					width:100px;
					height:100px;
					border-radius: 5px;
					border:solid 1px #ccc;
				}
				
				text-align:center;
			}
			.goods-attr{
				
				padding:10px 30px;
				.attr-item{
					text-align:left; 
					.at-left{
						width:60%;
						float:left;
						display:  block;
						
					}
					.at-right{
						width:39%;
						float:left;
						display:  block;
						 word-wrap:break-word;
						 word-break:break-all;
					}
				}
			}
		    .goods-buy{
				 
				width:100%;
				float:left;
				margin:10px 0px;
			}
			.price-wrap {
				padding: 0 10px;
				display: flex;
				align-items: center;

				.price {
					display: flex;
					color: $base-color;
					font-size: $ns-font-size-lg;

					p {
						font-size: $ns-font-size-base;
						display: flex;
						align-items: flex-end;
					}
				}

				.market-price {
					color: #838383;
					text-decoration: line-through;
					margin-left: 10px;
				}
			}

			.sale-num {
				padding: 0 10px;
				display: flex;
				color: #838383;
				font-size: $ns-font-size-sm;

				p {
					color: #4759a8;
				}
			}

			.shop_name {
				padding: 0 10px;
				display: flex;
				color: #838383;
			}

			.saling {
				padding: 0 10px;
				display: flex;
				font-size: $ns-font-size-sm;
				line-height: 1;

				.free-shipping {
					background: $base-color;
					color: #ffffff;
					padding: 3px 4px;
					border-radius: 2px;
					margin-right: 5px;
				}

				.is_own {
					color: #ffffff;
					background: #ff850f;
					border: 1px solid;
					margin-right: 5px;
					display: flex;
					align-items: center;
					padding: 3px 4px;
					border-radius: 2px;
				}

				.promotion-type {
					color: $base-color;
					border: 1px solid $base-color;
					display: flex;
					align-items: center;
					padding: 1px 3px;
				}
			}

			.item-bottom {
				display: flex;
				margin-top: 5px;

				.collection {
					flex: 1;
					border: 1px solid #e9e9e9;
					border-right: none;
					border-left: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}

				.cart {
					flex: 2;
					border: 1px solid #e9e9e9;
					border-right: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}
		}
	}
	.price-icon-wrap{
		display: inline-block;
		max-width: 35px;
		margin-left: 3px;
		line-height: 1;
		margin-top: 4px;
		img{
			max-width: 100%;
		}
	}

	.empty {
		margin-top: 45px;
	}

	.pager {
		text-align: center;
		margin-top: 30px;
	}
	
	.in-sousuo {
		width: 700px;
		padding-top: 20px;
		margin:auto; 
	 
		.sousuo-box {
			width: 100%;
			height: 46px;
			line-height:46px;
			border: 1px solid #61433c;
			box-sizing: border-box;
	        border-radius: 10px;
			.el-dropdown {
				padding: 0 10px;
				cursor: pointer;
	
				&::after {
					content: '';
					border-left: 1px solid #cfcfcf;
					margin-left: 5px;
				}
			}
	        .serbtn{
				background-color: #61433c;
				border-radius: 10px;
			    border: 3px solid #61433c;
				width:80px;
				height:45px;
				font-size:15px;
			}
			input {
				width: 380px;
				height: 22px;
				background: none;
				outline: none;
				border: none;
				margin: 4px;
			}
	
			button {
				border-radius: 0;
				float: right;
			}
		}
	
		.hot-search-words {
			width: 100%;
			height: 20px;
			margin-top: 10px;
			font-size: 14px;
	 
	
			ul {
				overflow: hidden;
				margin: 0;
				height: 21px;
				padding: 0;
				color: $base-color-info;
	
				li {
					cursor: pointer;
					list-style: none;
					float: left;
					margin-right: 15px;
	
					&:hover {
						color: $base-color;
					}
				}
			}
		}
	}
	
</style>
